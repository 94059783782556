<template>
    <client-page>
        <sub-visual sh="폼메일" tabActive="폼메일" bg="/images/test.jpg" />
        <page-section>
            <form-input skin="inquiries" />
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        FormInput,
    },
};
</script>
