var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "폼메일",
      "tabActive": "폼메일",
      "bg": "/images/test.jpg"
    }
  }), _c('page-section', [_c('form-input', {
    attrs: {
      "skin": "inquiries"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }